.admin-password-prompt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.prompt-content {
  background-color: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  animation: slideIn 0.3s ease-out;
}

.prompt-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.lock-icon {
  font-size: 2rem;
}

.prompt-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.8rem;
}

.info-section {
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
}

.info-card {
  background-color: #f8f9fa;
  padding: 1.2rem;
  border-radius: 12px;
  border: 1px solid #e9ecef;
}

.info-card h3 {
  margin: 0 0 0.8rem 0;
  color: #2196F3;
  font-size: 1.1rem;
}

.info-card p {
  margin: 0;
  color: #666;
  line-height: 1.4;
}

.info-card ul {
  margin: 0.5rem 0 0 0;
  padding-left: 1.2rem;
  color: #666;
}

.info-card li {
  margin: 0.3rem 0;
}

.password-inputs {
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  color: #555;
  font-size: 0.9rem;
  font-weight: 500;
}

.input-group input {
  padding: 0.8rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.input-group input:focus {
  border-color: #2196F3;
  outline: none;
}

.error-message {
  color: #dc3545;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  padding: 0.8rem;
  background-color: #fff5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.button-group button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
}

.button-group button:hover {
  background-color: #1976D2;
  transform: translateY(-1px);
}

.btn-icon {
  font-size: 1.2rem;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .prompt-content {
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    width: 90%;
  }
} 