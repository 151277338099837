.lifetime-stats-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lifetime-stats-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lifetime-stats-dialog {
  background: var(--surface-color);
  padding: 25px;
  border-radius: 12px;
  max-width: 700px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  color: var(--text-color);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.lifetime-stats-dialog h2 {
  margin-top: 0;
  color: var(--text-color);
}

.stats-info {
  margin-bottom: 20px;
}

.stats-info p {
  margin: 5px 0;
}

.stats-chart {
  width: 100%;
  height: 350px; /* Increased to accommodate the title */
  background-color: #f0f0f0;
  margin-bottom: 20px;
}

.lifetime-stats-dialog button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.lifetime-stats-dialog button:hover {
  background-color: #45a049;
}

.lifetime-stats-dialog h3 {
  margin-top: 8px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.stats-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px;
  margin-bottom: 25px;
}

.stats-card {
  background: var(--surface-color);
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
}

.stats-label {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 5px;
}

.stats-value {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
}

.stats-table {
  margin: 20px 0;
}

.stats-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.stats-table td {
  padding: 8px 12px;
  border-bottom: 1px solid var(--border-color);
}

.stats-table td:first-child {
  color: var(--text-secondary);
  width: 50%;
}

.value-cell {
  font-weight: 500;
  text-align: right;
}

.chart-section {
  background: var(--surface-color);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
  margin: 25px 0;
}

.chart-section h3 {
  margin-bottom: 15px;
  color: var(--text-color);
  text-align: center;
  font-size: 15px;
}

.close-button {
  display: block;
  margin: 15px auto 0;
  padding: 8px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.close-button:hover {
  background: #0056b3;
}

.stats-chart {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
