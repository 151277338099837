:root {
  /* Common colors */
  --primary-color: #3498db;
  --secondary-color: #2ecc71;

  /* Light theme */
  --light-background: #f4f4f4;
  --light-surface: #ffffff;
  --light-text: #333333;
  --light-text-secondary: #666666;
  --light-border: #e0e0e0;

  /* Dark theme */
  --dark-background: #1a1a1a;
  --dark-surface: #2d2d2d;
  --dark-text: #e0e0e0;
  --dark-text-secondary: #a0a0a0;
  --dark-border: #404040;
}

[data-theme="light"] {
  --background-color: var(--light-background);
  --surface-color: var(--light-surface);
  --text-color: var(--light-text);
  --text-secondary: var(--light-text-secondary);
  --border-color: var(--light-border);
}

[data-theme="dark"] {
  --background-color: var(--dark-background);
  --surface-color: var(--dark-surface);
  --text-color: var(--dark-text);
  --text-secondary: var(--dark-text-secondary);
  --border-color: var(--dark-border);
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-size: 24px; /* Increased base font size for TV display */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.App-main {
  display: flex;
  flex: 1;
  padding: 1rem;
  gap: 1rem;
  overflow: hidden;
}

.App-column {
  background-color: var(--surface-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  padding: 1rem;
  overflow: auto;
  max-height: calc(100vh - 2rem - 60px);
}

.leaderboard-column {
  flex: 5;
}

.history-column {
  flex: 3;
}

.App-footer {
  background-color: var(--surface-color);
  padding: 1rem;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.admin-buttons {
  display: flex;
  gap: 0.5rem;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  font-size: 20px;
  transition: all 0.2s ease;
}

.start-game { background-color: var(--secondary-color); color: white; }
.clear-selections { background-color: #e74c3c; color: white; }
.add-player { background-color: var(--primary-color); color: white; }
.admin-controls { background-color: #f39c12; color: white; }

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Leaderboard styles */
.Leaderboard {
  height: 100%;
  overflow-y: auto;
}

.Leaderboard table {
  width: 100%;
  border-collapse: collapse;
  font-size: 32px;
}

.Leaderboard th, .Leaderboard td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.Leaderboard th {
  background-color: var(--primary-color);
  color: white;
  position: sticky;
  top: 0;
}

.Leaderboard tr:nth-child(even) {
  background-color: #f8f8f8;
}

.Leaderboard tr:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}

.Leaderboard tr.selected {
  background-color: #d0d0d0;
}

.Leaderboard tr.unranked {
  color: #999;
  font-style: italic;
}

/* Game History styles */
.GameHistory .history-container {
  max-height: 500px;
  overflow-y: auto;
  font-size: 28px;
}

.GameHistory .history-item {
  padding: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.GameHistory .history-item:hover {
  background-color: #e8e8e8;
}

.GameHistory .history-item.selected {
  background-color: #d0d0d0;
}

/* Player Selection styles */
.PlayerSelection select {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 20px;
}

h2 {
  font-size: 36px;
}

/* Updated Scoreboard styles */
.Scoreboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #1a1a1a;
  overflow: hidden;
  padding: 2vh;
  box-sizing: border-box;
}

.score-container {
  display: flex;
  flex: 1;
  gap: 2vw;
  margin-bottom: 2vh;
}

.player-score {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 2vw;
  border-radius: 2vh;
}

.player-score.green {
  background-color: #4CAF50;
}

.player-score.blue {
  background-color: #2196F3;
}

.player-name {
  font-size: 5vh;
  color: white;
  padding: 1vh 1vw;
  border: 2px solid white;
  border-radius: 1vh;
  margin-bottom: 2vh;
}

.score {
  font-size: 35vh; /* Increased font size */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 2vh 0;
}

.score-buttons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 2vh;
}

.score-btn {
  padding: 2vh 4vw;
  font-size: 3vh;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  border-radius: 1vh;
  width: 45%;
}

.score-btn.plus {
  background-color: #45a049;
}

.score-btn.minus {
  background-color: #f44336;
}

.game-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 2vw;
  background-color: #333;
  border-radius: 1vh;
}

.center-buttons {
  display: flex;
  gap: 1vw;
}

.game-btn {
  padding: 1vh 2vw;
  font-size: 2vh;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  border-radius: 1vh;
}

.end-game {
  background-color: #2196F3;
}

.quit-game {
  background-color: #f44336;
}

.controls, .fullscreen {
  background-color: transparent;
  border: 1px solid white;
}

.controls-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2vh;
  border-radius: 1vh;
  z-index: 1000;
}

.controls-popup h3 {
  margin-top: 0;
}

.controls-popup button {
  margin-top: 1vh;
  padding: 1vh 2vw;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.5vh;
  cursor: pointer;
}

.btn.admin-controls {
  background-color: #FFA500; /* Orange color for admin button */
  color: white;
}

.btn.admin-controls:hover {
  background-color: #FF8C00; /* Darker orange on hover */
}

/* Add this new style for the admin page */
.admin-page {
  height: 100vh;
  overflow-y: auto;
}

/* Add this at the top of your App.css */
* {
  box-sizing: border-box;
}

input, select, textarea {
  box-sizing: border-box;
  max-width: 100%;
}

/* Player selection dropdowns */
.player-controls select {
  min-width: 200px;
  border: 2px solid #e9ecef;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M6 8L1 3h10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 2.5rem;
  cursor: pointer;
  appearance: none;
}

.player-controls select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  outline: none;
}

.player-controls select:hover {
  border-color: #ced4da;
}

/* Button hover effects */
.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Game transition animations */
@keyframes gameStart {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes gameEnd {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.game-transition-enter {
  animation: gameStart 0.3s ease-out;
}

.game-transition-exit {
  animation: gameEnd 0.3s ease-out;
}

.temp-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffd700;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  z-index: 1000;
}

.confirmation-message {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
