.Leaderboard table {
  width: 100%;
  border-collapse: collapse;
}

.Leaderboard th {
  padding: 12px 20px;
  text-align: left;
  font-size: 1.2em;
  background-color: var(--primary-color);
  color: white;
}

.Leaderboard td {
  padding: 16px 20px;
  font-size: 1.1em;
  line-height: 1.4;
  color: var(--text-color);
}

.Leaderboard tr {
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

[data-theme="light"] .Leaderboard tr:nth-child(even) {
  background-color: #f8f8f8;
}

[data-theme="light"] .Leaderboard tr:hover {
  background-color: #f0f0f0;
}

[data-theme="light"] .Leaderboard tr.selected {
  background-color: #e0e0e0;
}

[data-theme="dark"] .Leaderboard tr:nth-child(even) {
  background-color: #262626;
}

[data-theme="dark"] .Leaderboard tr:hover {
  background-color: #3d3d3d;
}

[data-theme="dark"] .Leaderboard tr.selected {
  background-color: #404040;
}

.Leaderboard td:nth-child(2) {
  font-weight: 500;
  font-size: 1.15em;
}

.Leaderboard span {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.Leaderboard tr.inactive td {
  color: var(--text-secondary);
  font-style: italic;
}

