.admin-controls {
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  color: #333;
}

/* Custom Scrollbar */
.admin-controls::-webkit-scrollbar {
  width: 12px;
}

.admin-controls::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

.admin-controls::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

.admin-controls::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Admin Sections */
.admin-section {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 15px;
}

.admin-section h3 {
  margin: 0;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-section h3:after {
  content: '▼';
  font-size: 0.8em;
  transition: transform 0.3s ease;
}

.admin-section.collapsed h3:after {
  transform: rotate(-90deg);
}

.admin-section-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 1000px;
}

.admin-section.collapsed .admin-section-content {
  max-height: 0;
  padding: 0;
}

/* Updated Button Base Styles */
.admin-controls button,
.admin-controls .btn {
  padding: 10px 20px;
  min-width: 160px;
  height: 40px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  text-align: center;
}

/* Button Colors */
.standard-btn {
  background-color: #4CAF50;
  border-color: #45a049;
}

.delete-btn {
  background-color: #e74c3c;
  border-color: #c0392b;
}

.reset-btn {
  background-color: #f39c12;
  border-color: #e67e22;
}

.save-btn {
  background-color: #2196F3;
  border-color: #1976D2;
}

.danger-btn {
  background-color: #dc3545;
  border-color: #c82333;
}

.exit-btn {
  background-color: #e74c3c;
  border-color: #c0392b;
  width: 100%;
  margin-top: 20px;
}

/* Button Hover States */
.standard-btn:hover {
  background-color: #45a049;
  border-color: #4CAF50;
}

.delete-btn:hover {
  background-color: #c0392b;
  border-color: #e74c3c;
}

.reset-btn:hover {
  background-color: #e67e22;
  border-color: #f39c12;
}

.save-btn:hover {
  background-color: #1976D2;
  border-color: #2196F3;
}

.danger-btn:hover {
  background-color: #c82333;
  border-color: #dc3545;
}

.exit-btn:hover {
  background-color: #c0392b;
  border-color: #e74c3c;
}

/* Button Group Layout */
.button-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.data-management-buttons {
  display: flex;
  gap: 10px;
  margin: 15px 0;
  flex-wrap: wrap;
}

/* Form Button Layout */
.admin-controls form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-controls form button {
  align-self: flex-start;
}

/* Settings List */
.settings-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.setting-item {
  background-color: #f8f9fa;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.setting-item label {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.setting-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.password-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

.password-input-container input {
  flex: 1;
  min-width: 0;
}

.password-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 1.2rem;
}

.password-toggle-btn:hover {
  opacity: 0.8;
}

/* Text colors */
.setting-item input {
  color: #333;
}

.delete-player-btn {
  background-color: #e74c3c; /* Red */
  color: white;
}

.reset-btn {
  background-color: #f39c12; /* Orange */
  color: white;
}

button.reset-defaults-btn {
  background-color: #f39c12; /* Orange */
  color: white;
}

button[type="button"].delete-player-btn {
  background-color: #e74c3c; /* Red */
  color: white;
}

.delete-btn {
  background-color: #e74c3c; /* Red */
  color: white;
}

.standard-btn:hover {
  background-color: #45a049;
}

.reset-btn:hover {
  background-color: #e67e22;
}

.save-btn:hover {
  background-color: #1976D2;
}

.reset-defaults-btn:hover {
  background-color: #e67e22;
}

/* Add these input styles */
.admin-controls input[type="text"],
.admin-controls input[type="password"],
.admin-controls input[type="number"],
.admin-controls select,
.admin-controls input[type="email"] {
  width: 100%;
  max-width: 300px;
  padding: 8px 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.admin-controls input[type="text"]:focus,
.admin-controls input[type="password"]:focus,
.admin-controls input[type="number"]:focus,
.admin-controls select:focus,
.admin-controls input[type="email"]:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}

/* Color input specific styling */
.admin-controls input[type="color"] {
  width: 50px;
  height: 40px;
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

/* Update button styles to ensure Reset to Defaults is properly colored */
.reset-defaults-btn {
  background-color: #f39c12 !important; /* Orange */
  color: white;
  width: auto;
  margin: 10px 0;
}

.reset-defaults-btn:hover {
  background-color: #e67e22 !important;
}

/* Form styling */
.admin-controls form {
  margin-bottom: 20px;
}

/* Add this to ensure all form elements follow the same box model */
.admin-controls *,
.admin-controls *::before,
.admin-controls *::after {
  box-sizing: border-box;
}

.upload-btn {
  position: relative;
  cursor: pointer;
}

.upload-btn input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.admin-section {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.admin-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
}

.theme-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: var(--surface-color);
  border-radius: 8px;
  margin-bottom: 15px;
}

.theme-toggle label {
  color: var(--text-color);
  font-weight: 500;
}

.theme-toggle select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--surface-color);
  color: var(--text-color);
}

/* Specific styles for download and upload buttons */
.download-btn, .upload-btn {
  background-color: white !important;  /* Changed from #f8f9fa to white */
  border: 2px solid #000 !important;
  color: #000 !important;
}

.download-btn:hover, .upload-btn:hover {
  background-color: #f8f9fa !important;  /* Lighter hover state */
  border: 2px solid #000 !important;
  color: #000 !important;
}

/* Button alignment */
.button-group {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap;
  justify-content: flex-start;  /* Explicitly set left alignment */
}
