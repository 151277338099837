.game-history {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.game-history h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--text-color);
}

.game-history-list {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 200px); /* Adjust this value as needed */
  padding-right: 15px;
  padding-left: 5px;
}

.game-history-item {
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--surface-color);
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-size: 1.1em;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
}

.game-history-item:last-child {
  border-bottom: none;
}

[data-theme="light"] .game-history-item:hover {
  background-color: #f0f0f0;
}

[data-theme="light"] .game-history-item.selected {
  background-color: #e0e0e0;
}

[data-theme="light"] .game-history-item:nth-child(even) {
  background-color: #f8f8f8;
}

[data-theme="dark"] .game-history-item:hover {
  background-color: #3d3d3d;
}

[data-theme="dark"] .game-history-item.selected {
  background-color: #404040;
}

[data-theme="dark"] .game-history-item:nth-child(even) {
  background-color: #262626;
}

.game-history-item .underdog {
  color: #ffd700;
}

.game-history-item.skunk span {
  color: red;
  font-weight: bold;
}

.game-history-item b {
  font-size: 1.05em;
  font-weight: 600;
}

.game-history-item + .game-history-item {
  margin-top: 4px;
}

.placement-match {
  display: block;
  margin-top: 4px;
  color: var(--text-secondary);
  font-size: 0.9em;
  font-style: italic;
}

.game-history-item.long-names {
  font-size: 0.9em;
}

.game-history-item.very-long-names {
  font-size: 0.8em;
}

.game-history-item .game-result {
  overflow: hidden;
}

.game-history-item.long-names .game-result {
  font-size: 0.9em;
}

.game-history-item.very-long-names .game-result {
  font-size: 0.8em;
}

.game-history-item .score-change {
  display: block;
  color: var(--text-secondary);
  font-style: italic;
  font-size: 1em;
}

.game-history-item .placement-match {
  display: block;
  color: var(--text-secondary);
  font-style: italic;
  font-size: 1em;
}
