.login-screen {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a1a 0%, #363636 100%);
  overflow: hidden;
}

.background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.login-content {
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.logo-container {
  margin-bottom: 2rem;
}

.logo-container h1 {
  font-size: 4rem;
  margin: 0;
  animation: bounce 2s infinite;
}

.logo-container h2 {
  color: white;
  margin: 1rem 0;
  font-size: 1.8rem;
  font-weight: 600;
}

.logo-container .subtitle {
  color: rgba(190, 190, 190, 0.8);
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  max-width: 300px;
  margin: 0 auto;
  line-height: 1.4;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.google-login-btn,
.local-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
}

.google-login-btn {
  background-color: #ffffff;
  color: #333;
}

.local-login-btn {
  background-color: #2196F3;
  color: white;
}

.btn-icon {
  font-size: 1.2rem;
  font-weight: bold;
}

.google-login-btn:hover,
.local-login-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  margin: 0.5rem 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.divider span {
  padding: 0 1rem;
  font-size: 0.9rem;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.attribution {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  z-index: 2;
}

.attribution a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.2s ease;
}

.attribution a:hover {
  color: white;
  text-decoration: underline;
}

.login-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.button-subtext {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  font-style: italic;
}