.victory-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: backgroundFlash 3.5s steps(1, end);
}

.victory-text {
  font-size: 15vh;
  font-weight: bold;
  text-align: center;
  animation: textFlash 3.5s steps(1, end);
}

@keyframes backgroundFlash {
  0%, 25%, 50%, 75% {
    background-color: white;
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    background-color: black;
  }
}

@keyframes textFlash {
  0%, 25%, 50%, 75% {
    color: black;
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    color: white;
  }
} 